import { Collapse, Drawer, List, ListItem, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { styleConfig } from "../../themesAndStyles/styleConfig"
import { navItems } from "./navItems"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { NavLink } from "react-router-dom"
import useGlobalStyles from "../../hooks/useGlobalStyles"
import isEqual from "lodash.isequal"
import { useIsNl } from "../../context/LocaleContext"

const useStyles = makeStyles((theme) => ({
  list: {
    background: styleConfig.navColor,
    color: "white",
    "@media (orientation: landscape)": {
      paddingLeft: 30,
    },
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  subMenuLabel: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subMenu: {
    width: "100%",
  },
}))

type OpenType = false | { label: { nl: string; gb: string }; path: string }[]

export interface MobileMenuProps {
  open: boolean
  handleClose: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  const isNl = useIsNl()
  const [open, setOpen] = useState<OpenType>(false)
  const globalStyles = useGlobalStyles()
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    props.handleClose()
  }

  return (
    <Drawer anchor="bottom" open={props.open} onClose={handleClose}>
      <List className={classes.list}>
        {navItems.map((i, index) =>
          i.subMenu ? (
            <ListItem
              className={classes.menuItem}
              onClick={() => (!open ? setOpen(i.subMenu) : setOpen(false))}
              key={index}
            >
              <div className={classes.subMenuLabel}>
                {isNl ? i.label.nl : i.label.gb}
                {isEqual(open, i.subMenu) ? <ExpandLess /> : <ExpandMore />}
              </div>
              <Collapse
                className={classes.subMenu}
                in={isEqual(open, i.subMenu)}
              >
                <List>
                  {i.subMenu.map((i, index) => (
                    <NavLink
                      onClick={handleClose}
                      key={index}
                      className={globalStyles.clearLink}
                      to={i.path}
                    >
                      <ListItem className={classes.menuItem}>
                        {isNl ? i.label.nl : i.label.gb}
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            </ListItem>
          ) : (
            <NavLink
              onClick={handleClose}
              key={index}
              className={globalStyles.clearLink}
              to={i.path}
            >
              <ListItem className={classes.menuItem}>
                {isNl ? i.label.nl : i.label.gb}
              </ListItem>
            </NavLink>
          )
        )}
      </List>
    </Drawer>
  )
}

export default MobileMenu
