import React, {
  useState,
  useContext,
  useEffect,
  createContext,
  ReactNode,
} from "react"
import http from "../services/http"
import { notify } from "../components/common/Notification"

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/api/websites/terms"
    : "https://www.gfrooster.nl/api/websites/terms"

type Term = {
  title: string
  content: string[]
}[]

type Terms = {
  nl: Term
  gb: Term
}

type Value = {
  terms: Terms | undefined
  loading: boolean
}

const TermsContext = createContext<Value | null>(null)

interface Props {
  children: ReactNode
}

const TermsProvider = (props: Props) => {
  const [terms, setTerms] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        setLoading(true)
        const { data: terms } = await http.get(url)
        setTerms(terms)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error.response.status === 429) notify(error.response.data, "error")
        else
          notify(
            "Er ging iets mis met het laden van de algemene voorwaarden",
            "error"
          )
      }
    }
    fetchTerms()
  }, [])

  return (
    <TermsContext.Provider value={{ terms, loading }}>
      {props.children}
    </TermsContext.Provider>
  )
}

const useTerms = () => useContext(TermsContext) as Value

export { TermsProvider, useTerms }
