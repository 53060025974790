// import { theme } from "./theme"

interface StyleConfig {
  navHeight: number
  navColor: string
  navTextColor: string
  navActiveColor: string
  navHoverColor: string
  navFontSize: number
  bannerHeight: number
  mobileSliderHeight: number
  mainFontSize: number | string
  inputVariant: any
  inputColor: any
  inputMargin: any
}

export const styleConfig: StyleConfig = {
  navHeight: 64,
  navColor: "#263238",
  navTextColor: "#fafafa",
  navActiveColor: "#39A9CC",
  navHoverColor: "",
  navFontSize: 18,
  bannerHeight: 800,
  mobileSliderHeight: 300,
  mainFontSize: "medium",
  inputVariant: "filled",
  inputColor: "primary",
  inputMargin: "dense",
}
