import React, { useState } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"

export let notify: (
  message: string,
  color?: "error" | "info" | "success",
  duration?: number
) => void

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  alert: {},
}))

export interface NotificationProps {}

const Notification: React.FC<NotificationProps> = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [color, setColor] = useState("info")
  const [duration, setDuration] = useState(6000)

  const openNotification = (
    message: string,
    color?: "error" | "info" | "success",
    duration?: number
  ) => {
    setOpen(true)
    setMessage(message)
    setColor(color || "info")
    setDuration(duration || 6000)
  }

  notify = openNotification

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alert className={classes.alert} onClose={handleClose} color={color}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Notification
