import React, { Fragment, useState } from "react"
import { makeStyles, Menu, MenuItem } from "@material-ui/core"
import { styleConfig } from "../../themesAndStyles/styleConfig"
import { useLocaleContext } from "../../context/LocaleContext"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import NL from "../../img/dutchFlag.png"
import GB from "../../img/britishFlag.png"

interface Props {
  isMobile?: boolean
}

const useStyles = makeStyles({
  expandIcon: {
    color: "white",
  },
  wrapper: (props: Props) => ({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: styleConfig.navHeight / 2 - 10,
    left: 10,
    "@media (orientation: landscape)": {
      left: props.isMobile ? 40 : 10,
    },
    "&:hover": {
      cursor: "pointer",
    },
  }),
  flagWrapper: (props: Props) => ({
    overflow: "hidden",
    borderRadius: 5,
    boxSizing: "border-box",
    height: 20,
    width: 30,
    padding: 0,
  }),
})

const LanguageSelect: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { locale, setLocale } = useLocaleContext()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <Fragment>
      <div
        className={classes.wrapper}
        onClick={handleOpen}
        aria-controls="language-select"
      >
        <div className={classes.flagWrapper}>
          <img
            alt="flag"
            src={locale === "nl" ? NL : GB}
            height={20}
            width={30}
          />
        </div>
        {Boolean(anchorEl) ? (
          <ExpandLess className={classes.expandIcon} />
        ) : (
          <ExpandMore className={classes.expandIcon} />
        )}
      </div>
      <Menu
        id="language-select"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setLocale("nl")
            handleClose()
          }}
        >
          Nederlands
        </MenuItem>
        <MenuItem
          onClick={() => {
            setLocale("gb")
            handleClose()
          }}
        >
          English
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default LanguageSelect
