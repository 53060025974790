import React, { Fragment, useState } from "react"
import { IconButton, makeStyles } from "@material-ui/core"
import { styleConfig } from "../../themesAndStyles/styleConfig"
import { Close, Menu } from "@material-ui/icons"
import MobileMenu from "./MobileMenu"
import { useLocation } from "react-router-dom"
import { navItems } from "./navItems"
import LanguageSelect from "./LanguageSelect"
import { useIsNl } from "../../context/LocaleContext"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: styleConfig.navColor,
    height: styleConfig.navHeight,
  },
  title: {
    marginLeft: 70,
    color: "white",
    "@media (orientation: landscape)": {
      marginLeft: 100,
    },
  },
  icon: {
    marginRight: 20,
    color: "white",
  },
}))

export interface MobileNavProps {}

const MobileNavBar: React.FC<MobileNavProps> = () => {
  const isNl = useIsNl()
  const { pathname } = useLocation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  let navObjects: { path: string; label: { nl: string; gb: string } }[] = []

  navItems.forEach((i) =>
    i.subMenu
      ? i.subMenu.forEach((i) => navObjects.push(i))
      : navObjects.push(i)
  )

  const label = navObjects.find((i) => i.path === pathname)?.label[
    isNl ? "nl" : "gb"
  ]

  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <LanguageSelect isMobile />
      <div className={classes.root}>
        <h3 className={classes.title}>{label}</h3>
        <IconButton onClick={() => setOpen(!open)} className={classes.icon}>
          {open ? <Close /> : <Menu />}
        </IconButton>
      </div>
      <MobileMenu open={open} handleClose={handleClose} />
    </Fragment>
  )
}

export default MobileNavBar
