import React from "react"
import { CircularProgress, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export interface LoadingSpinnerProps {
  size?: number
  color?: "secondary" | "primary"
  style?: object
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress
        style={props.style}
        size={props.size}
        color={props.color || "secondary"}
      />
    </div>
  )
}

export default LoadingSpinner
