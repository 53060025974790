type NavItems = (
  | { label: { nl: string; gb: string }; path: string; subMenu?: undefined }
  | {
      label: { nl: string; gb: string }
      subMenu: { label: { nl: string; gb: string }; path: string }[]
      path?: undefined
    }
)[]

export const navItems: NavItems = [
  {
    label: { nl: "Home", gb: "Home" },
    path: "/",
  },
  {
    label: { nl: "Gitaarles", gb: "Guitarlessons" },
    subMenu: [
      {
        label: { nl: "Over de les", gb: "About the lessons" },
        path: "/gitaarles",
      },
      {
        label: { nl: "Docenten", gb: "Teachers" },
        path: "/gitaarles/docenten",
      },
      {
        label: { nl: "Veel gestelde vragen", gb: "FAQ" },
        path: "/gitaarles/faq",
      },
    ],
  },
  {
    label: { nl: "Tarieven & Info", gb: "Prices & info" },
    subMenu: [
      {
        label: { nl: "Tarieven", gb: "Prices" },
        path: "/tarieven",
      },
      {
        label: { nl: "Algemene Voorwaarden", gb: "Terms and Conditions" },
        path: "/tarieven/algemene-voorwaarden",
      },
      {
        label: { nl: "Over ons", gb: "About us" },
        path: "/tarieven/over-ons",
      },
      {
        label: { nl: "Bedrijfsgegevens", gb: "Company Details" },
        path: "/tarieven/bedrijfsgegevens",
      },
    ],
  },
  {
    label: { nl: "Planning", gb: "Planning" },
    subMenu: [
      { path: "/planning", label: { nl: "Vakanties", gb: "Holidays" } },
      {
        path: "/planning/jaarplanning",
        label: { nl: "Jaarrooster", gb: "Year Schedule" },
      },
    ],
  },
  {
    label: { nl: "Inschrijvingen", gb: "Subscriptions" },
    path: "/inschrijven",
  },
  {
    label: { nl: "Contact", gb: "Contact" },
    path: "/contact",
  },
]
