import React, { Fragment, Suspense } from "react"
import { Switch, Route } from "react-router-dom"
import { makeStyles } from "@material-ui/core"

import Footer from "./components/Footer"
import Nav from "./components/nav/Nav"
import BackToTopBtn from "./components/BackToTopBtn"
import Notification from "./components/common/Notification"
import FullScreenLoader from "./components/common/FullScreenLoader"
import useFooterHeight from "./hooks/useFooterHeight"

const Faq = React.lazy(() => import("./components/gitaarles/Faq"))
const Prices = React.lazy(() => import("./components/prices&info/Prices"))
const Teacher = React.lazy(() => import("./components/gitaarles/Teacher"))
const About = React.lazy(() => import("./components/prices&info/About"))
const Terms = React.lazy(() => import("./components/prices&info/Terms"))
const Home = React.lazy(() => import("./components/home/Home"))
const Teachers = React.lazy(() => import("./components/gitaarles/Teachers"))
const Contact = React.lazy(() => import("./components/contact/Contact"))
const Vacations = React.lazy(() => import("./components/schedules/Vacations"))
const Subscribtions = React.lazy(
  () => import("./components/subscriptions/Subscribtions")
)
const CompanyDetails = React.lazy(
  () => import("./components/prices&info/CompanyDetails")
)
const GuitarLesson = React.lazy(
  () => import("./components/gitaarles/GuitarLesson")
)
const YearSchedule = React.lazy(
  () => import("./components/schedules/YearSchedule")
)

const useStyles = makeStyles({
  root: (props: { footerHeight: number }) => ({
    minHeight: `calc(100% - ${props.footerHeight}px)`,
  }),
  backToTop: {
    zIndex: 100,
  },
})

function App() {
  const footerHeight = useFooterHeight()
  const classes = useStyles({ footerHeight })

  return (
    <>
      <Suspense fallback={<FullScreenLoader />}>
        <Fragment>
          <div className={classes.root}>
            <Nav />
            <BackToTopBtn />
            <Switch>
              <Route path="/inschrijven" component={Subscribtions} />
              <Route path="/contact" component={Contact} />
              <Route exact path="/planning" component={Vacations} />
              <Route path="/planning/jaarplanning" component={YearSchedule} />
              <Route exact path="/tarieven" component={Prices} />
              <Route
                exact
                path="/tarieven/algemene-voorwaarden"
                component={Terms}
              />
              <Route exact path="/tarieven/over-ons" component={About} />
              <Route
                exact
                path="/tarieven/bedrijfsgegevens"
                component={CompanyDetails}
              />
              <Route exact path="/gitaarles" component={GuitarLesson} />
              <Route exact path="/gitaarles/docenten" component={Teachers} />
              <Route path="/gitaarles/docenten/:id" component={Teacher} />
              <Route path="/gitaarles/faq" component={Faq} />
              <Route exact path="/" component={Home} />
            </Switch>
          </div>
          <Footer />
        </Fragment>
      </Suspense>
      <Notification />
    </>
  )
}

export default App
