import React from "react"
import { makeStyles } from "@material-ui/core"
import LoadingSpinner from "./LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: "white",
  },
}))

interface Props {}

const FullScreenLoader: React.FC<Props> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LoadingSpinner />
    </div>
  )
}

export default FullScreenLoader
