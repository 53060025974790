import { useMediaQuery } from "@material-ui/core"
import React, { Fragment } from "react"
import MobileNavBar from "./MoblileNavBar"
import NavBar from "./NavBar"
import { isMobileOnly } from "react-device-detect"

export interface NavProps {}

const Nav: React.FC<NavProps> = () => {
  const mobile = useMediaQuery("(max-width: 760px)") || isMobileOnly
  return <Fragment>{mobile ? <MobileNavBar /> : <NavBar />}</Fragment>
}

export default Nav
