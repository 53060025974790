import { makeStyles } from "@material-ui/core"
import { styleConfig } from "../themesAndStyles/styleConfig"

const useStyles = makeStyles((theme) => ({
  tableCell: {
    fontFamily: "inherit",
    fontWeight: "bolder",
    fontSize: styleConfig.mainFontSize,
    color: theme.palette.primary.dark,
  },
  clearLink: {
    color: "white",
    textDecoration: "none",
    "&:visited": {
      color: "white",
    },
  },
}))

const useGlobalStyles = () => {
  return useStyles()
}

export default useGlobalStyles
