import { Menu, MenuItem, withStyles } from "@material-ui/core"
import React from "react"
import { NavLink } from "react-router-dom"
import { useIsNl } from "../../context/LocaleContext"
import useGlobalStyles from "../../hooks/useGlobalStyles"
import { styleConfig } from "../../themesAndStyles/styleConfig"

interface StyledProps {
  open: boolean
  anchorEl: Element | null
  keepMounted: boolean
  onClose: () => void
}

interface SubMenuProps {
  anchorEl: Element | null
  handleClose: () => void
  menuItems: { label: { nl: string; gb: string }; path: string }[]
  open?: boolean
  keepMounted?: boolean
  onClose?: () => void
}

const StyledMenu = withStyles((theme) => ({
  paper: {
    background: styleConfig.navColor,
    margin: 0,
    boxShadow: theme.shadows[0],
    borderTop: "none",
    borderRadius: 0,
    padding: 0,
    paddingTop: 0,
    width: 220,
  },
  list: {
    padding: 0,
  },
}))((props: StyledProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledItem = withStyles((theme) => ({
  root: {
    background: styleConfig.navColor,
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 0,
    color: styleConfig.navTextColor,
    padding: 15,
    fontSize: styleConfig.navFontSize - 2,
    fontFamily: "inherit",
    fontWeight: "bold",
    transition: "0.7s",
    "&:hover": {
      color: styleConfig.navActiveColor,
      cursor: "pointer",
      background: styleConfig.navHoverColor,
    },
  },
}))(MenuItem)

const SubMenu: React.FC<SubMenuProps> = (props) => {
  const isNl = useIsNl()
  const globalStyles = useGlobalStyles()
  return (
    <StyledMenu
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      keepMounted
      onClose={props.handleClose}
    >
      {props.menuItems.map((item, index) => (
        <NavLink key={index} className={globalStyles.clearLink} to={item.path}>
          <StyledItem onClick={props.handleClose}>
            {isNl ? item.label.nl : item.label.gb}
          </StyledItem>
        </NavLink>
      ))}
    </StyledMenu>
  )
}

export default SubMenu
