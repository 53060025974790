import { useState, useEffect } from "react"

const useFooterHeight = () => {
  const [footerHeight, setFooterHeight] = useState(0)

  useEffect(() => {
    const footer = document.getElementById("footer")
    setFooterHeight(footer?.offsetHeight || 0)
  }, [])

  return footerHeight
}

export default useFooterHeight
