import React, { useState } from "react"
import { makeStyles } from "@material-ui/core"
import { NavLink, useLocation } from "react-router-dom"
import clsx from "clsx"
import SubMenu from "./SubMenu"
import { styleConfig } from "../../themesAndStyles/styleConfig"
import { navItems } from "./navItems"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import isEqual from "lodash.isequal"
import LanguageSelect from "./LanguageSelect"
import { useIsNl } from "../../context/LocaleContext"

type SubMenuType = { path: string; label: { nl: string; gb: string } }[]

const useStyles = makeStyles((theme) => ({
  navBar: {
    background: styleConfig.navColor,
    width: "100%",
    height: styleConfig.navHeight,
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    boxShadow: theme.shadows[0],
  },
  navItem: {
    textDecoration: "none",
    fontWeight: "bolder",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: styleConfig.navTextColor,
    padding: 15,
    fontSize: 18,
    transition: "0.3s",
    "&:hover": {
      color: styleConfig.navActiveColor,
      cursor: "pointer",
      background: styleConfig.navHoverColor,
    },
  },
  active: {
    color: styleConfig.navActiveColor,
    background: styleConfig.navHoverColor,
    borderLeft: `2px solid ${styleConfig.navActiveColor}`,
    borderRight: `2px solid ${styleConfig.navActiveColor}`,
    "&:hover": {},
  },
  open: {
    border: "none",
  },
}))

const initialMenuItems = [{ path: "", label: { nl: "", gb: "" } }]

const NavBar: React.FC = () => {
  const isNl = useIsNl()
  const { pathname } = useLocation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuItems, setSubMenu] = useState(initialMenuItems)

  const openMenu = (e: any, subMenu: SubMenuType) => {
    setSubMenu(subMenu)
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setSubMenu(initialMenuItems)
  }

  return (
    <nav className={classes.navBar}>
      {navItems.map((i) =>
        i.path ? (
          <NavLink
            to={i.path}
            key={i.path}
            className={clsx(
              classes.navItem,
              pathname === i.path ? classes.active : ""
            )}
          >
            {isNl ? i.label.nl : i.label.gb}
          </NavLink>
        ) : (
          <div
            onClick={(e) => openMenu(e, i.subMenu as SubMenuType)}
            key={i.label.nl}
            className={clsx(
              classes.navItem,
              i.subMenu!.find((i) => i.path === pathname) ? classes.active : "",
              isEqual(menuItems, i.subMenu) ? classes.open : ""
            )}
          >
            {isNl ? i.label.nl : i.label.gb}{" "}
            {isEqual(i.subMenu, menuItems) ? <ExpandLess /> : <ExpandMore />}
          </div>
        )
      )}
      <SubMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        menuItems={menuItems}
      />
      <LanguageSelect />
    </nav>
  )
}

export default NavBar
