import React, {
  ReactNode,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react"
import http from "../services/http"
import { notify } from "../components/common/Notification"

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/api/websites/yearschedule/midden"
    : "https://www.gfrooster.nl/api/websites/yearschedule/midden"

const initialState = [
  {
    from: "1970-01-01",
    note: "",
    region: "zuid",
    till: "1970-01-01",
    week: 0,
    weekType: "",
    __v: 0,
    _id: "",
  },
]

export type YearScheduleWeekType = {
  from: string
  note: string
  region: string
  till: string
  week: number
  weekType: string
  __v: number
  _id: string
}

export type YearScheduleType = YearScheduleWeekType[]

type Value = {
  yearSchedule: YearScheduleType
  loading: boolean
}

const YearScheduleContext = createContext<Value | null>(null)

interface State {
  children: ReactNode
}

const YearScheduleProvider = (props: State) => {
  const [yearSchedule, setYearSchedule] = useState(initialState)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchYearSchedule = async () => {
      setLoading(true)
      try {
        const { data } = await http.get(url)
        setYearSchedule(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error.response.status === 429) notify(error.response.data, "error")
        else
          notify("Er ging iets mis met het laden van het jaarrooser", "error")
      }
    }
    fetchYearSchedule()
  }, [])

  return (
    <YearScheduleContext.Provider value={{ yearSchedule, loading }}>
      {props.children}
    </YearScheduleContext.Provider>
  )
}

const useYearSchedule = () => useContext(YearScheduleContext) as Value

export { YearScheduleProvider, useYearSchedule }
