import React, { useState, useContext, ReactNode, useEffect } from "react"

export type Locale = "nl" | "gb"

type Value = {
  locale: Locale
  setLocale: React.Dispatch<React.SetStateAction<"nl" | "gb">>
}

interface Props {
  children: ReactNode
}

const LocaleContext = React.createContext<Value | null>(null)

const LocaleContextProvider = (props: Props) => {
  const computeLocale = () => (localStorage.getItem("locale") as Locale) || "nl"

  const [locale, setLocale] = useState<Locale>(() => computeLocale())

  useEffect(() => localStorage.setItem("locale", locale), [locale])

  return (
    <LocaleContext.Provider value={{ locale, setLocale } as Value}>
      {props.children}
    </LocaleContext.Provider>
  )
}

const useLocaleContext = () => useContext(LocaleContext) as Value
const useIsNl = () => useContext(LocaleContext)!.locale === "nl"
export { useIsNl, useLocaleContext, LocaleContextProvider }
